<template>
	<v-sheet class="main-detail">
		<v-layout class="section-main-content">
			<div class="details-body">
				<div class="p-sticky bg-white">
					<v-layout class="align-center light-blue-bg px-4 py-2 flex-wrap">
						<v-flex md12 class="d-flex flex-wrap">
							<v-chip
								class="mr-1 text-capitalize"
								color="cyan white--text"
								x-small
								v-if="false && this.project && this.project.status == 1"
								>Draft
							</v-chip>
							<v-chip
								class="mr-1 text-capitalize"
								text-color=""
								outlined
								color="orange white--text"
								x-small
								v-if="false && this.project && this.project.status == 3"
								>Renewed
							</v-chip>
							<v-chip
								class="mr-1 text-capitalize"
								color="green white--text"
								x-small
								v-if="false && this.project && this.project.status == 3"
								>Completed
							</v-chip>
							<v-chip
								class="mr-1 text-capitalize"
								color="red white--text"
								x-small
								v-if="false && this.project && this.project.status == 4"
								>Cancelled
							</v-chip>
							<div v-if="false && project.renewed > 0" class="mb-2">
								<v-avatar color="red" size="27" class="mr-2">
									<span class="white--text fw-600"> R {{ project.renewed }}</span>
								</v-avatar>
							</div>
							<v-spacer></v-spacer>
							<template v-if="getPermission('project:update')">
								<v-btn
									v-if="false && project.status != 4 && project.status != 3"
									depressed
									tile
									class="red darken-4 mr-2 my-auto"
									color="white--text"
									v-on:click="cancelProject()"
									><v-icon class="mr-1">mdi-cancel</v-icon>
									Cancel
								</v-btn>
								<!-- <v-btn
									v-if="project.status != 4 && project.status != 3"
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text"
									v-on:click="routeToUpdate(project.id)">
									
									<v-icon class="mr-1">mdi-pencil</v-icon>
									Edit
								</v-btn> -->
							</template>
							<template v-if="getPermission('project:create')">
								<v-btn
									v-if="false && project.status != 4 && this.project.status != 3"
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text"
									@click="routeToRenew(project.id)"
								>
									Renew
								</v-btn>
							</template>
							<v-btn depressed tile class="my-auto" v-on:click="goBack()">
								<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
								back
							</v-btn>
						</v-flex>
						<p
							class="my-auto text-capitalize custom-heading"
							style="margin-top: -4px !important; font-size: 20px; font-weight: 600"
							v-if="customer && customer.company_name"
						>
							{{ customer.company_name }} :-
							{{ customer.barcode }}
						</p>
						<v-flex md12 class="d-flex flex-wrap">
							<div class="w-25">
								<div class="mt-2">
									<!-- <pre>{{ project }}</pre> -->
									<span class="fw-600">Software Type: </span>
									<span class="fw-500 primary--text" v-if="software && software.software_type">
										{{ software.software_type }}
									</span>
									<em v-else class="text-muted"> no software type</em>
								</div>
							</div>
							<div class="w-25">
								<div class="mt-2">
									<span class="fw-600"> Sale Date: </span>
									<span class="fw-500 primary--text text-capitalize" v-if="software && software.sale_date">
										{{ formatDate(software.sale_date) }}
									</span>
									<em v-else class="text-muted"> no sale date</em>
								</div>
							</div>
							<div class="w-25">
								<div class="mt-2">
									<span class="fw-600">Contract #: </span>
									<span v-if="software && software.contract_barcode">
										{{ software?.contract_barcode }}
									</span>
									<em v-else class="text-muted"> no contract</em>
								</div>
							</div>
							<div class="w-25">
								<div class="mt-2">
									<span class="fw-600">Project #: </span>
									<span v-if="software && software.project_barcode">
										{{ software?.project_barcode }}
									</span>
									<em v-else class="text-muted"> no project</em>
								</div>
							</div>
							<!-- <div class="w-25">
								<div class="mt-2">
									<span class="fw-600"> Phone Number : </span>
									<span
										class="fw-500 primary--text cursor-pointer"
										v-if="customer && customer.phone_number"
										v-on:click="copyNumber(customer.phone_number)"
									>
										{{ customer.phone_number }}
									</span>
									<em v-else class="text-muted"> no phone number</em>
								</div>
							</div> -->
						</v-flex>
						<v-flex md12 class="d-flex flex-wrap">
							<div class="w-25">
								<div class="mt-2">
									<!-- <pre>{{ project }}</pre> -->
									<span class="fw-600">Service Provider: </span>

									<span class="fw-500" v-if="software && software.service_provider">
										{{ software.service_provider }}
									</span>
									<em v-else class="text-muted"> no service provider</em>
								</div>
							</div>
							<div class="w-25">
								<div class="mt-2">
									<span class="fw-600">Live (Expected): </span>
									<span class="fw-500" v-if="software && software.live_expected">
										{{ formatDate(software.live_expected) }}
									</span>
									<em v-else class="text-muted"> no live expected</em>
								</div>
							</div>
							<div class="w-25">
								<div class="mt-2">
									<span class="fw-600">Contract Period: </span>
									<span v-if="software && software.duration">
										<span class="fw-500 primary--text cursor-pointer" v-if="software && software.duration">
											{{ software.duration }} months
										</span>
										<span class="fw-500 primary--text cursor-pointer" v-if="software && software.time_free">
											+{{ software.time_free }} free months
										</span>
									</span>
									<em v-else class="text-muted"> no contract period</em>
								</div>
							</div>
							<div class="w-25">
								<div class="mt-2">
									<span class="fw-600">Project Cost: </span>
									<span v-if="software && software.project_cost">
										{{ formatMoney(software.project_cost) }}
									</span>
									<em v-else class="text-muted"> no cost</em>
								</div>
							</div>
						</v-flex>
						<v-flex md12 class="d-flex flex-wrap">
							<div class="w-25">
								<div class="mt-2">
									<!-- <pre>{{ project }}</pre> -->
									<span class="fw-600">Server: </span>
									<span class="fw-500">
										<span class="fw-500" v-if="software && software?.server_name">
											{{ software?.server_name }}
										</span>
										<em v-else class="text-muted"> no server</em>
									</span>
								</div>
							</div>
							<div class="w-25">
								<div class="d-flex">
									<div class="mt-2">
										<span class="fw-600">Live (Actual): </span>
										<span class="fw-500" v-if="software && software.live_actual">
											{{ formatDate(software.live_actual) }}
										</span>
										<em v-else class="text-muted"> no live actual</em>
									</div>
								</div>
							</div>
							<div class="w-25">
								<div class="mt-2">
									<span class="fw-600"> Contract Status: </span>
									<span class="fw-500" v-if="software && software.contract_status">
										<Chip
											small
											v-if="software.contract_status == 1"
											class="mr-2 mb-1"
											outlined
											text="Draft"
											text-color=""
											color="cyan"
										></Chip>
										<Chip
											small
											v-if="software.contract_status == 2"
											class="mr-2 mb-1"
											outlined
											text="Accepted"
											text-color=""
											color="green"
										></Chip>
										<Chip
											small
											v-if="software.contract_status == 3"
											class="mr-2 mb-1"
											outlined
											text="Rejected"
											text-color=""
											color="red"
										></Chip>
									</span>
									<em v-else class="text-muted"> no status</em>
								</div>
							</div>
							<div class="w-25">
								<div class="mt-2">
									<span class="fw-600"> Project Status : </span>
									<span class="fw-500" v-if="software && software.project_status">
										<Chip
											small
											v-if="software.project_status == 1"
											class="mr-2 mb-1"
											outlined
											text="Draft"
											text-color=""
											color="cyan"
										></Chip>
										<Chip
											small
											v-if="software.project_status == 4"
											class="mr-2 mb-1"
											outlined
											text="Cancelled"
											text-color=""
											color="red"
										></Chip>
										<Chip
											small
											v-if="software.project_status == 3"
											class="mr-2 mb-1"
											outlined
											text="Completed"
											text-color=""
											color="green"
										></Chip>
									</span>
									<em v-else class="text-muted"> no status</em>
								</div>
							</div>

							<!-- <div class="w-25">
								<div class="mt-2">
									<span class="fw-600">Total Invoice : </span>
									<span class="fw-500">
										<span v-if="total_invoice" class="primary--text">
											{{ total_invoice }}
										</span>
									</span>
								</div>
							</div> -->
						</v-flex>
						<!-- <v-flex md12 class="d-flex flex-wrap">
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Company Name : </span>
										<span class="fw-500" v-if="customer && customer.company_name">
											{{ customer.company_name }}
										</span>
										<em v-else class="text-muted"> no company name</em>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Company Name : </span>
										<span class="fw-500" v-if="customer && customer.company_name">
											{{ customer.company_name }}
										</span>
										<em v-else class="text-muted"> no company name</em>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600"> Company UEN : </span>
										<span class="fw-500" v-if="customer && customer.company_uen">
											{{ customer.company_uen }}
										</span>
										<em v-else class="text-muted"> no company uen</em>
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600"> Sales Persons : </span>
										<span
											class="fw-500"
											v-if="salesPersonList && salesPersonList.attendies && salesPersonList.attendies.length"
										>
											<v-chip
												class="mb-1 mr-1"
												small
												v-for="(data, index) in salesPersonList.attendies"
												:key="index"
											>
												<v-avatar left small>
													<img v-if="data.profile_img" :src="data.profile_img" />
													<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
												</v-avatar>
												<span v-if="data && data.first_name">{{ data.first_name }}</span>
											</v-chip>
										</span>
										<em class="text-muted" v-else> no sales person </em>
									</div>
								</div>
							</v-flex> -->
						<!-- <v-flex md12 class="d-flex flex-wrap">
							<div class="w-25">
								<div class="mt-2">
									<span class="fw-600">Cancel Reason: </span>
									<span class="fw-500 primary--text" v-if="project && project.cancel_reason">
										{{ project.cancel_reason }}
									</span>
									<em v-else class="text-muted"> no cancel reason</em>
								</div>
							</div>
							<div class="w-25">
								<div class="mt-2">
									<span class="fw-600">Cancel Remark : </span>
									<span class="fw-500 primary--text" v-if="project && project.cancel_remark">
										{{ project.cancel_remark }}
									</span>
									<em v-else class="text-muted"> no cancel remark</em>
								</div>
							</div>
						</v-flex> -->
					</v-layout>
					<v-layout class="px-4 py-2">
						<v-tabs
							v-model="projectTab"
							background-color="transparent"
							color="blue"
							class="custom-tab-transparent w-100"
							active-class="blue darken-4 text-white"
							hide-slider
						>
							<v-tab href="#customer"><v-icon small left>mdi-information-outline</v-icon>Overview</v-tab>
							<v-tab href="#software">
								<v-icon small left>mdi-timeline-clock-outline</v-icon>
								Recurring
							</v-tab>

							<v-tab href="#contract" v-if="false"
								><v-icon small left>mdi-information-outline</v-icon>Contract</v-tab
							>
							<v-tab
								href="#seo"
								v-if="false && project && project.checkbox_type && project.checkbox_type.includes('seo')"
								><v-icon small left>mdi-note-plus-outline</v-icon>Seo</v-tab
							>
							<v-tab href="#hosting" v-if="false"> <v-icon small left>mdi-repeat</v-icon>Hosting</v-tab>
							<v-tab href="#ssl" v-if="false"
								><v-icon small left>mdi-timeline-clock-outline</v-icon>Ssl</v-tab
							>
							<v-tab href="#web" v-if="false"> <v-icon small left>mdi-account-group</v-icon>Web</v-tab>
							<v-tab href="#domain" v-if="false">
								<v-icon small left>mdi-file-certificate-outline</v-icon>
								Domain
							</v-tab>

							<v-tab href="#maintenace" v-if="false">
								<v-icon small left>mdi-file-document-outline</v-icon>
								Maintenance
							</v-tab>

							<!-- <v-tab href="#keyword_report"> <v-icon small left>mdi-file-document-outline</v-icon>Keyword Report</v-tab> -->
							<v-tab href="#submitted_analysis" v-if="false">
								<v-icon small left>mdi-file-document-outline</v-icon>Submitted analysis</v-tab
							>
							<v-tab href="#invoice" v-if="false">
								<v-icon small left>mdi-file-document-outline</v-icon>Invoice</v-tab
							>
							<v-tab href="#file" v-if="false"
								><v-icon small left>mdi-file-document-multiple</v-icon>Cancel Files</v-tab
							>
							<v-tab href="#activity" v-if="false"> <v-icon small left>mdi-poll</v-icon>Activity </v-tab>
						</v-tabs>
					</v-layout>
				</div>
				<div class="tab-layout-content project-tb-content px-4">
					<v-tabs-items v-model="projectTab">
						<v-tab-item value="customer">
							<div class="detail-card bg-white">
								<div
									class="card--header d-flex align-items-center pa-2 border-bottom"
									v-on:click="open_detail = !open_detail"
								>
									<div class="font-level-3-bold">Overview</div>
									<v-spacer></v-spacer>
									<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
								</div>
								<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
									<table class="w-70">
										<tr>
											<td class="text-muted fw-500 px-3 py-2">Tags</td>
											<td class="fw-600 px-3 py-1">
												<template v-if="getValue(software, 'tags') && getValue(software, 'tags').length">
													<v-chip
														v-for="tag in getValue(software, 'tags')"
														:key="tag.id"
														:color="tag.color"
														class="mr-1"
													>
														<span class="white--text">{{ mod_string(tag.tag, "_", " ") }}</span>
													</v-chip>
												</template>
												<template v-else> <em class="text-muted">no tags</em></template>
											</td>
										</tr>

										<tr>
											<td class="text-muted fw-500 px-3 py-2">Project Manager (SG)</td>
											<td class="fw-600 px-3 py-1">
												<span class="fw-500" v-if="software.project_manager_sg_obj">
													<v-chip class="mb-1 mr-1" small>
														<v-avatar left small>
															<img
																v-if="
																	software.project_manager_sg_obj && software.project_manager_sg_obj.profile_img
																"
																:src="software.project_manager_sg_obj.profile_img"
															/>
															<img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/>
														</v-avatar>
														<span
															v-if="
																software.project_manager_sg_obj && software.project_manager_sg_obj.display_name
															"
															>{{ software.project_manager_sg_obj.display_name }}</span
														>
													</v-chip>
												</span>
												<em class="text-muted" v-else> no project manager person </em>
											</td>
										</tr>

										<tr>
											<td class="text-muted fw-500 px-3 py-2">Primary Tech</td>
											<td class="fw-600 px-3 py-1">
												<span class="fw-500" v-if="software.primary_support_obj">
													<v-chip class="mb-1 mr-1" small>
														<v-avatar left small>
															<img
																v-if="software.primary_support_obj && software.primary_support_obj.profile_img"
																:src="software.primary_support_obj.profile_img"
															/>
															<img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/>
														</v-avatar>
														<span
															v-if="software.primary_support_obj && software.primary_support_obj.display_name"
															>{{ software.primary_support_obj.display_name }}</span
														>
													</v-chip>
												</span>
												<em class="text-muted" v-else> no primary tech </em>
											</td>
										</tr>

										<tr>
											<td class="text-muted fw-500 px-3 py-2">Secondary Tech</td>
											<td class="fw-600 px-3 py-1">
												<span class="fw-500" v-if="software.secondary_support_obj">
													<v-chip class="mb-1 mr-1" small>
														<v-avatar left small>
															<img
																v-if="software.secondary_support_obj && software.secondary_support_obj.profile_img"
																:src="software.secondary_support_obj.profile_img"
															/>
															<img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/>
														</v-avatar>
														<span
															v-if="software.secondary_support_obj && software.secondary_support_obj.display_name"
															>{{ software.secondary_support_obj.display_name }}</span
														>
													</v-chip>
												</span>
												<em class="text-muted" v-else> no secondary tech </em>
											</td>
										</tr>

										<tr v-if="software.is_grant">
											<td class="text-muted fw-500 px-3 py-2">Grant</td>
											<td class="fw-600 px-3 py-1" style="text-transform: capitalize">
												<template v-if="software && software.grant_type"> {{ software.grant_type }}</template>
												<template v-else> <em class="text-muted">no grant type</em></template>
											</td>
										</tr>
										<tr v-if="software.is_grant">
											<td class="text-muted fw-500 px-3 py-2">LOA Date</td>
											<td class="fw-600 px-3 py-1">
												<template v-if="software && software.loa_date">
													{{ formatDate(software.loa_date) }}</template
												>
												<template v-else> <em class="text-muted">no loa date</em></template>
											</td>
										</tr>
										<tr v-if="software.is_grant">
											<td class="text-muted fw-500 px-3 py-2">Grant Filling Date</td>
											<td
												class="fw-600 px-3 py-1"
												style="text-transform: capitalize; text-wrap: wrap; max-width: 651px"
											>
												<template v-if="software && software.filling_date">
													{{ formatDate(software.filling_date) }}</template
												>
												<template v-else> <em class="text-muted">no filling date</em></template>
											</td>
										</tr>
										<tr v-if="software.is_grant">
											<td class="text-muted fw-500 px-3 py-2">System Live</td>
											<td class="fw-600 px-3 py-1" style="text-transform: capitalize">
												<template v-if="software && software.system_live">
													{{ formatDate(software.system_live) }}</template
												>
												<template v-else> <em class="text-muted">no system live date</em></template>
											</td>
										</tr>
										<tr v-if="software.is_grant">
											<td class="text-muted fw-500 px-3 py-2" style="min-width: 11vw; max-width: 11vw">
												Claim Deadline
											</td>
											<td class="fw-600 px-3 py-1" style="min-width: 25vw; max-width: 25vw">
												<template v-if="software && software.claim_deadline">
													{{ formatDate(software.claim_deadline) }}</template
												>
												<template v-else> <em class="text-muted">no claim deadline date</em></template>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<!-- <Overview v-if="false"></Overview> -->
						</v-tab-item>

						<v-tab-item value="contract" v-if="false">
							<div class="detail-card bg-white">
								<div
									class="card--header d-flex align-items-center pa-2 border-bottom"
									v-on:click="open_detail = !open_detail"
								>
									<div class="font-level-3-bold">Contract Details</div>
									<v-spacer></v-spacer>
									<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
								</div>
								<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
									<table class="w-100">
										<tr>
											<td class="text-muted fw-500 px-3 py-2">Barcode #</td>
											<td class="fw-600 px-3 py-1">
												<template v-if="contract && contract.barcode"> {{ contract.barcode }}</template>
											</td>
										</tr>

										<tr>
											<td class="text-muted fw-500 px-3 py-2">Title</td>
											<td class="fw-600 px-3 py-1">
												<template v-if="contract && contract.title"> {{ contract.title }}</template>
												<template v-else> <em>no title</em></template>
											</td>
										</tr>

										<tr>
											<td class="text-muted fw-500 px-3 py-2">Remark</td>
											<td class="fw-600 px-3 py-1">
												<template v-if="contract && contract.remark"> {{ contract.remark }}</template>
												<template v-else> <em>no remark</em></template>
											</td>
										</tr>
									</table>
								</div>
							</div>
							<!-- <Overview v-if="false"></Overview> -->
						</v-tab-item>

						<v-tab-item value="seo">
							<ProjectSoe
								:customer-id="customer.id"
								:project-id="softwareId"
								:contract-id="contract.id"
								:customer-service="customer_services_renewal"
								:project="project"
							>
							</ProjectSoe>
						</v-tab-item>

						<v-tab-item value="hosting">
							<ProjectHosting
								:customer-id="customer.id"
								:project-id="softwareId"
								:contract-id="contract.id"
								:customer-hosting="customer_hosting"
								:project="project"
								:client-data="clientData"
							>
							</ProjectHosting>
							<!-- <div class="detail-card bg-white">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">Hosting Details</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<template
											v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].type == 'client'"
										>
											<table class="w-100">
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].type">
															{{ customer_hosting[0].type }}</template
														>
														<template v-else> <em>no type </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Cpanel/Server URL</td>
													<td class="fw-600 px-3 py-1">
														<template
															v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].cpanal_url"
														>
															{{ customer_hosting[0].cpanal_url }}</template
														>
														<template v-else> <em> no cpanel/server url</em></template>
													</td>
												</tr>

												<tr>
													<td class="text-muted fw-500 px-3 py-2">Duration</td>
													<td class="fw-600 px-3 py-1">
														<template
															v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].duration"
														>
															{{ customer_hosting[0].duration }}(Month)</template
														>
														<template v-else> <em>no duration</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Start Date</td>
													<td class="fw-600 px-3 py-1">
														<template
															v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].start_date"
														>
															{{ formatDate(customer_hosting[0].start_date) }}</template
														>
														<template v-else> <em>no start date </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">End Date</td>
													<td class="fw-600 px-3 py-1">
														<template
															v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].end_date"
														>
															{{ formatDate(customer_hosting[0].end_date) }}</template
														>
														<template v-else> <em> no end date</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">remark</td>
													<td class="fw-600 px-3 py-1">
														<template
															v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].remark"
														>
															{{ customer_hosting[0].remark }}</template
														>
														<template v-else> <em>no remark</em></template>
													</td>
												</tr>
											</table>
										</template>
										<template v-else>
											<table class="w-100">
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].type">
															{{ customer_hosting[0].type }}</template
														>
														<template v-else> <em>no type </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">server</td>
													<td class="fw-600 px-3 py-1">
														<template
															v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].server"
														>
															{{ customer_hosting[0].server }}</template
														>
														<template v-else> <em> no server</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Server Provider</td>
													<td class="fw-600 px-3 py-1">
														<template
															v-if="
																customer_hosting && customer_hosting[0] && customer_hosting[0].service_provider
															"
														>
															{{ customer_hosting[0].service_provider }}</template
														>
														<template v-else> <em> no service provider</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Cpanel/Server URL</td>
													<td class="fw-600 px-3 py-1">
														<template
															v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].cpanal_url"
														>
															{{ customer_hosting[0].cpanal_url }}</template
														>
														<template v-else> <em> no cpanel/server url</em></template>
													</td>
												</tr>

												<tr>
													<td class="text-muted fw-500 px-3 py-2">Duration</td>
													<td class="fw-600 px-3 py-1">
														<template
															v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].duration"
														>
															{{ customer_hosting[0].duration }}(Month)</template
														>
														<template v-else> <em>no duration</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Start Date</td>
													<td class="fw-600 px-3 py-1">
														<template
															v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].start_date"
														>
															{{ formatDate(customer_hosting[0].start_date) }}</template
														>
														<template v-else> <em>no start date </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">End Date</td>
													<td class="fw-600 px-3 py-1">
														<template
															v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].end_date"
														>
															{{ formatDate(customer_hosting[0].end_date) }}</template
														>
														<template v-else> <em> no end date</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Cost</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].cost">
															${{ customer_hosting[0].cost }}</template
														>
														<template v-else> <em>no cost</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">remark</td>
													<td class="fw-600 px-3 py-1">
														<template
															v-if="customer_hosting && customer_hosting[0] && customer_hosting[0].remark"
														>
															{{ customer_hosting[0].remark }}</template
														>
														<template v-else> <em>no remark</em></template>
													</td>
												</tr>
											</table>
										</template>
									</div>
								</div> -->
						</v-tab-item>

						<v-tab-item value="ssl">
							<ProjectSLL
								:customer-id="customer.id"
								:project-id="softwareId"
								:contract-id="contract.id"
								:customer-ssl="customer_ssl"
								:project="project"
							>
							</ProjectSLL>
							<!-- <div class="detail-card bg-white">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">SSL Details</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].type == 'client'">
											<table class="w-100">
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].type">
															{{ customer_ssl[0].type }}</template
														>
														<template v-else> <em>no type </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">service provider</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].service_provider">
															{{ customer_ssl[0].service_provider }}</template
														>
														<template v-else> <em> no service provider</em></template>
													</td>
												</tr>

												<tr>
													<td class="text-muted fw-500 px-3 py-2">Duration</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].duration">
															{{ customer_ssl[0].duration }}(Month)</template
														>
														<template v-else> <em>no duration</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Start Date</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].start_date">
															{{ formatDate(customer_ssl[0].start_date) }}</template
														>
														<template v-else> <em>no start date </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">End Date</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].end_date">
															{{ formatDate(customer_ssl[0].end_date) }}</template
														>
														<template v-else> <em> no end date</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">remark</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].remark">
															{{ customer_ssl[0].remark }}</template
														>
														<template v-else> <em>no remark</em></template>
													</td>
												</tr>
											</table>
										</template>
										<template v-else>
											<table class="w-100">
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].type">
															{{ customer_ssl[0].type }}</template
														>
														<template v-else> <em>no type </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Service provider</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].service_provider">
															{{ customer_ssl[0].service_provider }}</template
														>
														<template v-else> <em> no service provider</em></template>
													</td>
												</tr>

												<tr>
													<td class="text-muted fw-500 px-3 py-2">Duration</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].duration">
															{{ customer_ssl[0].duration }}(Month)</template
														>
														<template v-else> <em>no duration</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Start Date</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].start_date">
															{{ formatDate(customer_ssl[0].start_date) }}</template
														>
														<template v-else> <em>no start date </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">End Date</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].end_date">
															{{ formatDate(customer_ssl[0].end_date) }}</template
														>
														<template v-else> <em> no end date</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Cost</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].cost">
															${{ customer_ssl[0].cost }}</template
														>
														<template v-else> <em>no cost</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">remark</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_ssl && customer_ssl[0] && customer_ssl[0].remark">
															{{ customer_ssl[0].remark }}</template
														>
														<template v-else> <em>no remark</em></template>
													</td>
												</tr>
											</table>
										</template>
									</div>
								</div> -->
						</v-tab-item>

						<v-tab-item value="web">
							<ProjectWeb
								:customer-id="customer.id"
								:project-id="softwareId"
								:contract-id="contract.id"
								:customer-web="customer_web"
								:project="project"
							>
							</ProjectWeb>
							<!-- <div class="detail-card bg-white">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">Web Details</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<template v-if="customer_web && customer_web[0] && customer_web[0].type == 'client'">
											<table class="w-100">
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].type">
															{{ customer_web[0].type }}</template
														>
														<template v-else> <em>no type </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">CMS URL</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].cms_url">
															{{ customer_web[0].cms_url }}</template
														>
														<template v-else> <em> no CMS URL</em></template>
													</td>
												</tr>

												<tr>
													<td class="text-muted fw-500 px-3 py-2">Remark</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].remark">
															{{ customer_web[0].remark }}</template
														>
														<template v-else> <em>no remark</em></template>
													</td>
												</tr>
											</table>
										</template>
										<template v-else>
											<table class="w-100">
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].type">
															{{ customer_web[0].type }}</template
														>
														<template v-else> <em>no type </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Domain</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].domain">
															{{ customer_web[0].domain }}</template
														>
														<template v-else> <em> no domain</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">CMS</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].cms">
															{{ customer_web[0].cms }}</template
														>
														<template v-else> <em> no cms</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">CMS URL</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].cms_url">
															{{ customer_web[0].cms_url }}</template
														>
														<template v-else> <em> no cms url</em></template>
													</td>
												</tr>

												<tr>
													<td class="text-muted fw-500 px-3 py-2">Duration</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].duration">
															{{ customer_web[0].duration }}(Month)</template
														>
														<template v-else> <em>no duration</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Start Date</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].start_date">
															{{ formatDate(customer_web[0].start_date) }}</template
														>
														<template v-else> <em>no start date </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">End Date</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].end_date">
															{{ formatDate(customer_web[0].end_date) }}</template
														>
														<template v-else> <em> no end date</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Cost</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].cost">
															${{ customer_web[0].cost }}</template
														>
														<template v-else> <em>no cost</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">remark</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].remark">
															{{ customer_web[0].remark }}</template
														>
														<template v-else> <em>no remark</em></template>
													</td>
												</tr>
											</table>
										</template>
									</div>
								</div> -->
						</v-tab-item>

						<v-tab-item value="domain">
							<ProjectDomain
								:customer-id="customer.id"
								:project-id="softwareId"
								:contract-id="contract.id"
								:customer-domain="domain"
								:project="project"
							>
							</ProjectDomain>
							<!-- <div class="detail-card bg-white">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">Domain Details</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<template v-if="domain && domain[0] && domain[0].type == 'client'">
											<table class="w-100">
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="domain && domain[0] && domain[0].type">
															{{ domain[0].type }}</template
														>
														<template v-else> <em>no type </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Name</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="domain && domain[0] && domain[0].name">
															{{ domain[0].name }}</template
														>
														<template v-else> <em> no name</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Duration</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="domain && domain[0] && domain[0].duration">
															{{ domain[0].duration }}(Month)</template
														>
														<template v-else> <em>no duration</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Start Date</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="domain && domain[0] && domain[0].start_date">
															{{ formatDate(domain[0].start_date) }}</template
														>
														<template v-else> <em>no start date </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">End Date</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="domain && domain[0] && domain[0].end_date">
															{{ formatDate(domain[0].end_date) }}</template
														>
														<template v-else> <em> no end date</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Cost</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="domain && domain[0] && domain[0].cost">
															${{ domain[0].cost }}</template
														>
														<template v-else> <em>no cost</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Remark</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="domain && domain[0] && domain[0].remark">
															{{ domain[0].remark }}</template
														>
														<template v-else> <em>no remark</em></template>
													</td>
												</tr>
											</table>
										</template>
										<template v-else>
											<table class="w-100">
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="domain && domain[0] && domain[0].type">
															{{ domain[0].type }}</template
														>
														<template v-else> <em>no type </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Name</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="domain && domain[0] && domain[0].name">
															{{ domain[0].name }}</template
														>
														<template v-else> <em> no domain</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Duration</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].duration">
															{{ customer_web[0].duration }}(Month)</template
														>
														<template v-else> <em>no duration</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Start Date</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].start_date">
															{{ formatDate(customer_web[0].start_date) }}</template
														>
														<template v-else> <em>no start date </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">End Date</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].end_date">
															{{ formatDate(customer_web[0].end_date) }}</template
														>
														<template v-else> <em> no end date</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Cost</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].cost">
															${{ customer_web[0].cost }}</template
														>
														<template v-else> <em>no cost</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">remark</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="customer_web && customer_web[0] && customer_web[0].remark">
															{{ customer_web[0].remark }}</template
														>
														<template v-else> <em>no remark</em></template>
													</td>
												</tr>
											</table>
										</template>
									</div>
								</div> -->
						</v-tab-item>

						<v-tab-item value="software">
							<div class="detail-card bg-white">
								<div class="card--body pa-2">
									<div>
										<v-row>
											<v-col md="12">
												<table class="w-100">
													<tr>
														<td colspan="2">
															<div class="form-grp-title d-flex">Recurring</div>
														</td>
													</tr>
													<tr>
														<td class="text-muted fw-500 px-3 py-2">Start Date</td>
														<td class="fw-600 px-3 py-1">
															<v-icon left>mdi-calendar</v-icon>
															<template v-if="getValue(software, 'recurring_date')">
																{{ formatDate(getValue(software, "recurring_date")) }}</template
															>
															<template v-else> <em class="text-muted">no start date</em></template>
														</td>
													</tr>

													<tr>
														<td class="text-muted fw-500 px-3 py-2">Users</td>
														<td class="fw-600 px-3 py-1">
															<v-icon left>mdi-account</v-icon>
															<template v-if="getValue(software, 'no_of_users')">
																{{ getValue(software, "no_of_users") }}</template
															>
															<template v-else> <em class="text-muted">no users</em></template>
														</td>
													</tr>

													<tr>
														<td class="text-muted fw-500 px-3 py-2">Total Cost (SGD)</td>
														<td class="fw-600 px-3 py-1">
															<template v-if="getValue(software, 'total_cost')">
																{{ formatMoney(getValue(software, "total_cost")) }}
																<span class="fw-500"
																	>({{ mod_string(getValue(software, "time_interval"), "_", " ") }})
																</span>
															</template>
															<template v-else> <em class="text-muted">no total cost</em></template>
														</td>
													</tr>

													<tr>
														<td class="text-muted fw-500 px-3 py-2">Cost Per User (SGD)</td>
														<td class="fw-600 px-3 py-1">
															<template v-if="getValue(software, 'cost_per_user')">
																{{ formatMoney(getValue(software, "cost_per_user")) }}
																<span class="fw-500"
																	>({{ mod_string(getValue(software, "time_interval"), "_", " ") }})
																</span>
															</template>
															<template v-else> <em class="text-muted">no cost per user</em></template>
														</td>
													</tr>

													<tr>
														<td class="text-muted fw-500 px-3 py-2">Remark</td>
														<td class="fw-600 px-3 py-1 text-justify" style="max-width: 22rem">
															<template v-if="getValue(software, 'remark')">
																{{ getValue(software, "remark") }}</template
															>
															<template v-else> <em class="text-muted">no remark</em></template>
														</td>
													</tr>
												</table>
											</v-col>
										</v-row>
									</div>
								</div>
							</div>
						</v-tab-item>

						<v-tab-item value="maintenace">
							<ProjectMaintenace
								:customer-id="customer.id"
								:project-id="softwareId"
								:contract-id="contract.id"
								:maintenance="maintenance"
								:project="project"
							>
							</ProjectMaintenace>
							<!-- <div class="detail-card bg-white">
									<div
										class="card--header d-flex align-items-center pa-2 border-bottom"
										v-on:click="open_detail = !open_detail"
									>
										<div class="font-level-3-bold">Maintenace Details</div>
										<v-spacer></v-spacer>
										<v-icon>{{ open_detail ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
									</div>
									<div class="card--body d-flex align-items-center pa-2" v-if="open_detail">
										<template v-if="maintenance && maintenance[0] && maintenance[0].type == 'client'">
											<table class="w-100">
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<tr>
															<td class="custom-table-details">No information available</td>
														</tr>
													</td>
												</tr>
											</table>
										</template>
										<template v-else>
											<table class="w-100">
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Type</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="maintenance && maintenance[0] && maintenance[0].type">
															{{ maintenance[0].type }}</template
														>
														<template v-else> <em>no type </em></template>
													</td>
												</tr>

												<tr>
													<td class="text-muted fw-500 px-3 py-2">Duration</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="maintenance && maintenance[0] && maintenance[0].duration">
															{{ maintenance[0].duration }}(Month)</template
														>
														<template v-else> <em>no duration</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Start Date</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="maintenance && maintenance[0] && maintenance[0].start_date">
															{{ formatDate(maintenance[0].start_date) }}</template
														>
														<template v-else> <em>no start date </em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">End Date</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="maintenance && maintenance[0] && maintenance[0].end_date">
															{{ formatDate(maintenance[0].end_date) }}</template
														>
														<template v-else> <em> no end date</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">Cost</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="maintenance && maintenance[0] && maintenance[0].cost">
															${{ maintenance[0].cost }}</template
														>
														<template v-else> <em>no cost</em></template>
													</td>
												</tr>
												<tr>
													<td class="text-muted fw-500 px-3 py-2">remark</td>
													<td class="fw-600 px-3 py-1">
														<template v-if="maintenance && maintenance[0] && maintenance[0].remark">
															{{ maintenance[0].remark }}</template
														>
														<template v-else> <em>no remark</em></template>
													</td>
												</tr>
											</table>
										</template>
									</div>
								</div> -->
						</v-tab-item>

						<v-tab-item value="keyword_report" v-if="false">
							<SeoReport
								v-if="projectTab == 'keyword_report'"
								:customer-id="customer.id"
								:project-id="softwareId"
								:contract-id="contract.id"
							>
							</SeoReport>
						</v-tab-item>

						<v-tab-item value="submitted_analysis" v-if="false">
							<SeoAnalysis
								v-if="projectTab == 'submitted_analysis'"
								:customer-id="customer.id"
								:project-id="softwareId"
								:contract-id="contract.id"
							>
							</SeoAnalysis>
						</v-tab-item>

						<v-tab-item value="invoice">
							<!-- <RecurringInvoiceTab
									v-if="projectTab == 'invoice'"
									:related-id="project.customer"
									internal
									:internal-id="softwareId"
								></RecurringInvoiceTab> -->
							<IntenalInvoiceTab
								v-if="projectTab == 'invoice'"
								:customer-id="project.customer"
								:billing-type="project.billing_type"
								:internal-id="softwareId"
								:type="'project-detail'"
								:project-data="project"
								v-on:invoiceLength="invoiceCount($event)"
							></IntenalInvoiceTab>
						</v-tab-item>

						<v-tab-item value="file">
							<Files
								v-if="projectTab == 'file'"
								class="mx-4"
								type="project"
								:url="'project'"
								:type-id="softwareId"
							>
							</Files>
						</v-tab-item>

						<v-tab-item value="activity">
							<div>
								<div v-for="(logs, index) in logData" :key="index">
									<ActivityTab :open_details="true" :log="logs" title="Project"></ActivityTab>
								</div>
							</div>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</div>
		</v-layout>
		<v-file-input ref="uploadFile" class="d-none" v-on:change="uploadFile($event)"></v-file-input>
		<!-- sidebar drawers -->
		<!-- <ActivityLog
			:drawer="open_dialog.activity_log"
			v-on:close="open_dialog.activity_log=false"></ActivityLog> -->

		<!-- <CancelProject
			v-if="cancelDialog"
			v-on:close="cancelDialog = false"
			:cancel-dialog="cancelDialog"
			:project-id="softwareId"
			v-on:success="(cancelDialog = false), getSingleSoftware()"
		>
		</CancelProject> -->
	</v-sheet>
</template>
<script>
/* import Overview from "@/view/module/project/Overview"; */
/* import RecurringInvoiceTab from "@/view/module/components/RecurringInvoiceTab.vue"; */
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SeoAnalysis from "@/view/module/project/Seo-Analysis.vue";
import SeoReport from "@/view/module/project/Seo-Report.vue";
import ProjectSoe from "@/view/module/project/Project-Seo-List.vue";
import ProjectHosting from "@/view/module/project/Project-Hosting.vue";
import ProjectWeb from "@/view/module/project/Project-Web.vue";
import ProjectSLL from "@/view/module/project/Project-Sll.vue";
import ProjectDomain from "@/view/module/project/Project-Domain.vue";
import ProjectMaintenace from "@/view/module/project/Project-Maintenace.vue";
import { HostingEventBus } from "@/core/lib/hosting.lib";
//import ActivityLog from "@/view/module/components/ActivityLog";
import { GET } from "@/core/services/store/request.module";
import { toSafeInteger } from "lodash"; //filter
import IntenalInvoiceTab from "@/view/module/components/Invoice-Internal-listing.vue";
import ActivityTab from "@/view/pages/leads/components/ActivityTab";
//import CancelProject from "@/view/module/project/Cancel-Project";
import Files from "@/view/components/Files";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import Chip from "@/view/components/Chip";
import objectPath from "object-path";

export default {
	name: "Software-details",
	data() {
		return {
			software: {},
			search_open: false,
			project: {},
			open_detail: true,
			cancelDialog: false,
			customer: {},
			clientData: false,
			customer_hosting: [],
			customer_services: [],
			customer_services_renewal: [],
			customer_ssl: [],
			customer_software: [],
			customer_web: [],
			salesPersonList: {},
			logData: [],
			contract: {},
			maintenance: [],
			domain: [],
			softwareId: 0,
			total_invoice: 0,
			open_dialog: {
				notes: false,
				task: false,
				meeting: false,
				reminder: false,
				add_company: false,
				add_deal: false,
				add_attachment: false,
				create_project: false,
				activity_log: false,
				reminder_drawer: false,
			},
			panel: [0],
			/* projectTab: "overview", */
			task_action: false,
			open_details: false,
			assign_note: "Add Description",
			file_list: [],
		};
	},
	components: {
		ActivityTab,
		//CancelProject,
		// ReminderTab,
		SeoAnalysis,
		SeoReport,
		Files,
		/* RecurringInvoiceTab, */
		IntenalInvoiceTab,
		/* Overview, */
		ProjectSoe,
		ProjectDomain,
		ProjectSLL,
		ProjectHosting,
		ProjectMaintenace,
		ProjectWeb,
		Chip,
		/* ProjectMember, */
		//ActivityLog,
	},
	methods: {
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		getValue(object, key) {
			if (key == "server") {
				let obj = objectPath.get(object, key);
				return obj?.name;
			}
			return objectPath.get(object, key);
		},
		mod_string(text, saperator = "", joiner = "") {
			let splitArr = text ? text.split(saperator) : [];
			let mod_arr = splitArr.map((v) => v.charAt(0).toUpperCase() + v.slice(1));
			return mod_arr.join(joiner);
		},
		cancelProject() {
			this.cancelDialog = true;
		},
		routeToUpdate(id) {
			this.$router.push({
				name: "update-project",
				params: { id },
				query: { t: new Date().getTime() },
			});
		},
		renewedProject(id) {
			this.softwareId = id;
			this.getSingleSoftware();
			this.$router.push({
				name: "project-detail",
				params: { id },
				query: { t: new Date().getTime() },
			});
		},
		copyNumber(data) {
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},
		routeToRenew(id) {
			this.$router.push({
				name: "create-renewed-project",
				query: { t: new Date().getTime(), project_renew: toSafeInteger(id) },
			});
		},
		invoiceCount(count) {
			this.total_invoice = count;
		},
		pageTitle() {
			return "Lead Details";
		},
		uploadFile(data) {
			const file_size = (data.size / 1024).toFixed(1) + "kb";
			const data_opj = { icon: "mdi-file-check-outline", name: data.name, size: file_size };
			this.file_list.push(data_opj);
		},
		selectFile() {
			this.$refs["uploadFile"].$refs["input"].click();
		},
		remove_file(index) {
			this.file_list.splice(index, 1);
		},
		taskActivity() {
			this.$store
				.dispatch(GET, {
					url: `project-log/${this.softwareId}`,
				})
				.then((data) => {
					this.logData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},

		getSingleSoftware() {
			this.$store
				.dispatch(GET, {
					url: `softwares/details/${this.softwareId}`,
				})
				.then((data) => {
					//console.log(data);
					this.software = data;
					// this.customer = data.customer;
					// this.salesPersonList = data.attendie[0];
					// this.customer_hosting = data.customer_hosting;
					// let accept = filter(this.customer_hosting, (row) => row.type == "client");
					// if (accept.length > 0) {
					// 	this.clientData = true;
					// }
					// this.customer_services = data.customer_services;
					// this.customer_services_renewal = data.customer_services_renewal;
					// this.customer_ssl = data.customer_ssl;
					// this.customer_software = this.getValue(data, "software.0");
					// this.customer_web = data.customer_web;
					// this.domain = data.domain;
					// this.maintenance = data.maintenance;
					// this.contract = data.contract;
					// this.total_invoice = data.total_invoice;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		projectActivityfun() {
			this.$store
				.dispatch(GET, { url: `project-activity/${this.softwareId}` })
				.then((data) => {
					this.projectData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	beforeMount() {
		this.softwareId = toSafeInteger(this.$route.params.id);
	},
	computed: {
		projectTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.projectTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "overview";
			},
		},
	},
	mounted() {
		if (!this.getPermission("software:view") && this.softwareId > 0) {
			this.$router.replace({
				name: "software",
				query: { t: new Date().getTime() },
			});
		}
		this.getSingleSoftware();
		//this.taskActivity();
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Software", disabled: false, href: "/software" },
			{ text: "Detail", disabled: true },
		]);
		HostingEventBus.$on("refresh", () => {
			this.getSingleSoftware();
		});
	},
};
</script>
